<template>
  <vs-sidebar
    v-model="isActive"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} CURRENCY</h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isActive = false"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- Currency -->
      <div>
        <vs-label text="Currency" />
        <vs-input
          v-model="dataCurrency"
          v-validate="'required'"
          class="w-full"
          name="currency"
        />
        <v-error :err="errors" field="currency" />
      </div>

      <!-- Currency sign -->
      <div class="mt-4">
        <vs-label text="Currency sign" />
        <vs-input
          v-model="dataSign"
          v-validate="'required'"
          class="w-full"
          name="sign"
        />
        <v-error :err="errors" field="sign" />
      </div>

      <!-- Rate -->
      <div class="mt-4">
        <vs-label text="Rate" />
        <small class="block text-warning">
          If it is the base currency, the rate should be 1
        </small>
        <vs-input
          v-model="dataRate"
          v-validate="'required|min:0'"
          class="w-full"
          name="rate"
          type="number"
          step="0.01"
          min="0"
        />
        <v-error :err="errors" field="rate" />
      </div>

      <!-- Base -->
      <div class="mt-4">
        <vs-label text="Base currency" />
        <vs-checkbox v-model="dataIsBase" name="is_base" />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import { mapActions } from 'vuex';
import vSelect from 'vue-select';

import { SettingAction } from '@/store/actionTypes';

import customErrorMixin from '@/mixins/customErrorMixin';
import sidebarMixin from '@/mixins/sidebarMixin';

import vError from '@/components/VError';

export default {
  components: {
    vError,
    vSelect,
  },
  mixins: [customErrorMixin, sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: null,
      dataCurrency: '',
      dataSign: '',
      dataRate: 0,
      dataIsBase: false,
    };
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataCurrency &&
        this.dataSign &&
        this.dataRate > 0
      );
    },
  },
  watch: {
    active(val) {
      if (!val) {
        return;
      }

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const { id, currency, sign, rate, is_base } = this.data;

        this.dataId = id;
        this.dataCurrency = currency;
        this.dataSign = sign;
        this.dataRate = rate;
        this.dataIsBase = is_base;

        this.initValues();
      }
    },
  },
  methods: {
    ...mapActions('setting', [
      SettingAction.addCurrency,
      SettingAction.updateCurrency,
    ]),

    initValues() {
      if (this.data.id) {
        return;
      }

      this.dataId = null;
      this.dataCurrency = '';
      this.dataSign = '';
      this.dataRate = 0;
      this.dataIsBase = false;
    },
    async handleSubmit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      const obj = {
        id: this.dataId,
        currency: this.dataCurrency,
        sign: this.dataSign,
        rate: this.dataRate,
        is_base: this.dataIsBase,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          await this.updateCurrency(obj);

          this.notifySuccess(
            'Currency updated',
            'The currency has been successfully updated.'
          );

          this.$emit('close');
          this.initValues();
        } catch (error) {
          this.handleCustomError(error, 'Problem with updating currency.');
        }
      } else {
        delete obj.id;

        try {
          await this.addCurrency(obj);

          this.notifySuccess(
            'Currency added',
            'The currency has been successfully added.'
          );

          this.$emit('close');
          this.initValues();
        } catch (error) {
          this.handleCustomError(error, 'Problem with adding currency.');
        }
      }

      this.$vs.loading.close();
    },
  },
};
</script>
