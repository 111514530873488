<template>
  <data-list-view-wrapper>
    <currency-sidebar
      v-if="$gate.can(['create_settings', 'edit_settings'])"
      :active="addNewDataSidebar"
      :data="sidebarData"
      @close="toggleDataSidebar"
    />

    <vx-table
      ref="table"
      v-model="selected"
      :max-items="itemsPerPage"
      search
      :data="currencies"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <button-create
            v-if="$gate.can('create_settings')"
            title="Add currency"
            @click="addNewData"
          />
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="currency">Currency</vs-th>
        <vs-th sort-key="sign">Sign</vs-th>
        <vs-th sort-key="rate">Rate</vs-th>
        <vs-th sort-key="is_base">Base</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
            :data="tr"
            class="clickable-row"
          >
            <vs-td>
              <p class="currency">{{ tr.currency }}</p>
            </vs-td>

            <vs-td>
              <p class="sign">{{ tr.sign }}</p>
            </vs-td>

            <vs-td>
              <p class="rate">{{ tr.rate }}</p>
            </vs-td>

            <vs-td>
              <p v-if="tr.is_base" class="is_base text-success">Yes</p>
              <p v-else class="is_base text-danger">No</p>
            </vs-td>

            <vs-td width="120" class="whitespace-no-wrap">
              <feather-icon
                v-if="$gate.can('edit_settings')"
                icon="EditIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                class="ml-2"
                @click.stop="editData(tr)"
              />
              <feather-icon
                v-if="$gate.can('delete_settings')"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirmDelete(tr.id, tr.currency)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vx-table>
  </data-list-view-wrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { SettingAction } from '@/store/actionTypes';

import confirmationMixin from '@/mixins/confirmationMixin';
import dataListMixin from '@/mixins/dataListMixin';
import moduleSettings from '@/store/settings/moduleSettings';

import ButtonCreate from '@/views/components/buttons/ButtonCreate';
import CurrencySidebar from '@/views/sidebars/CurrencySidebar';
import DataListViewWrapper from '@/views/wrappers/DataListViewWrapper';
import VxTable from '@/components/vx-table/vxTable';

export default {
  components: {
    ButtonCreate,
    CurrencySidebar,
    DataListViewWrapper,
    VxTable,
  },
  mixins: [confirmationMixin, dataListMixin],
  computed: {
    ...mapState('setting', {
      currencies: ({ currencies }) => currencies,
    }),

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.currencies.length;
    },
  },
  methods: {
    ...mapActions('setting', [
      SettingAction.fetchAll,
      SettingAction.updateCurrency,
      SettingAction.removeCurrency,
    ]),

    registerStoreModules() {
      if (!moduleSettings.isRegistered) {
        this.$store.registerModule('setting', moduleSettings);
        moduleSettings.isRegistered = true;
      }
    },
    addNewData() {
      if (!this.$gate.can('create_settings')) {
        return;
      }

      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    async deleteData(id) {
      if (!this.$gate.can('delete_settings')) {
        return;
      }

      this.$vs.loading();

      try {
        await this.removeCurrency(id);

        this.notifySuccess(
          'Currency deleted',
          'The currency has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a currency.');
      }

      this.$vs.loading.close();
    },
    editData(data) {
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await this.fetchAll();
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.$vs.loading.close();
  },
};
</script>
