import { SettingsMutation } from '@/store/mutationTypes';

export default {
  [SettingsMutation.SET_SETTINGS](state, settings) {
    state.currencies = settings.currencies;
  },
  [SettingsMutation.ADD_CURRENCIES](state, item) {
    state.currencies.unshift(item);
  },
  [SettingsMutation.UPDATE_CURRENCIES](state, currency) {
    const index = state.currencies.findIndex((el) => el.id === currency.id);
    Object.assign(state.currencies[index], currency);
  },
  [SettingsMutation.REMOVE_CURRENCIES](state, id) {
    const currencies = state.currencies.filter((el) => el.id !== id);
    state.currencies = currencies;
  },
};
