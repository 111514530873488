export default {
  methods: {
    handleCustomError(error, customMessage) {
      if (error.response.data.errors) {
        const customError = Object.values(error.response.data.errors)[0][0];
        this.notifyError(null, customError, false);
      } else {
        this.notifyError(error, customMessage);
      }
    },
  },
};
