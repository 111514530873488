import axios from '@/axios';
import { SettingAction } from '@/store/actionTypes';
import { SettingsMutation } from '@/store/mutationTypes';

function setDefaultBaseCurrency(commit, currencies) {
  const currentBase = currencies.find((el) => el.is_base);

  if (currentBase) {
    const { currency, sign, rate } = currentBase;

    commit(
      'UPDATE_CURRENCY',
      {
        currency,
        currencySign: sign,
        currencyRate: rate,
      },
      { root: true }
    );
  }
}

export default {
  [SettingAction.fetchAll]({ commit }) {
    return new Promise((resolve, reject) => {
      return axios
        .get('/settings')
        .then(({ data }) => {
          commit(SettingsMutation.SET_SETTINGS, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [SettingAction.addCurrency]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/settings/currencies', item)
        .then(({ data }) => {
          commit(SettingsMutation.SET_SETTINGS, data);
          setDefaultBaseCurrency(commit, data.currencies);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [SettingAction.updateCurrency]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/settings/currencies/${item.id}`, item)
        .then(({ data }) => {
          commit(SettingsMutation.SET_SETTINGS, data);
          setDefaultBaseCurrency(commit, data.currencies);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [SettingAction.removeCurrency]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/settings/currencies/${id}`)
        .then(({ data }) => {
          commit(SettingsMutation.REMOVE_CURRENCIES, id);
          resolve(data);
        })
        .catch(reject);
    });
  },
};
